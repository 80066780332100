import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/App.scss';
import './styles/media.scss';
import { Provider } from 'react-redux';
import store from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="container " >
   <Provider store={store}>
    <App />
  </Provider>
  </div>
);
