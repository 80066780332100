import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './price.scss'
import { Helmet } from 'react-helmet';
import PriceInfo from '../../components/PriceInfo/PriceInfo';
import MyButton from '../../components/UI/button/MyButton';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Price1 = ({ setShowTermBundle, setButtonText, setButtonShow, setPriceBottomShow, setWhiteWrapper, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();
    useEffect(() => {
        setButtonShow(false);
        if (setWhiteWrapper) {
            setWhiteWrapper(true)
        }
        // setEventName('web_price')
        // setEventProperties({
        //     web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
        //     session_id: sessionId,
        //     user_id: localStorage.getItem('userId')
        // })
        setPriceBottomShow(false);
        localStorage.setItem("price", 1)
        // localStorage.setItem("plan", '4-Week Plan')

        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const [activePlan, setActivePlan] = useState(1);
    const [activeSale, setActiveSale] = useState('64% OFF');
    const plans = [
        {
            id: 0,
            name: '2 weeks',
            after: 'two weeks',
            price: '$19.95',
            oldPrice: '$42.00',
            priceDay: '$1.42/day',
            sale: '54% OFF',
            features: [
                {
                    text: 'Launch up to 50 campaigns',
                    status: true
                },
                {
                    text: 'Build up to 100 creatives',
                    status: true
                },
                {
                    text: 'Create up to 10 AI&nbsp;avatar videos',
                    status: true
                },
                {
                    text: 'Add unlimited products',
                    status: true
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true
                },
                {
                    text: 'Get 24/7 support anytime you need it',
                    status: true
                },
            ]
        },
        {
            id: 1,
            name: '1 month',
            after: 'the first month',
            price: '$29.95',
            oldPrice: '$83.00',
            // activeTrial: 'Most Popular',
            priceDay: '$1.00/day',
            sale: '64% OFF',
            features: [
                {
                    text: 'Launch up to 100 campaigns',
                    status: true
                },
                {
                    text: 'Build up to 250 creatives',
                    status: true
                },
                {
                    text: 'Create up to 20 AI&nbsp;avatar videos',
                    status: true
                },
                {
                    text: 'Add unlimited products',
                    status: true
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true
                },
                {
                    text: 'Get 24/7 support anytime you need it',
                    status: true
                },
            ]
        },
        {
            id: 2,
            name: '2 months',
            after: 'two months',
            price: '$49.95',
            oldPrice: '$167.00',
            priceDay: '$0.86/day',
            sale: '70% OFF',
            features: [
                {
                    text: 'Launch up to 150 campaigns',
                    status: true
                },
                {
                    text: 'Build up to 500 creatives',
                    status: true
                },
                {
                    text: 'Create up to 30 AI&nbsp;avatar videos',
                    status: true
                },
                {
                    text: 'Add unlimited products',
                    status: true
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true
                },
                {
                    text: 'Get 24/7 support anytime you need it',
                    status: true
                },
            ]
        },
    ];


    const handlePlanClick = (planId, planName, plansale) => {
        setActivePlan(planId);
        localStorage.setItem("price", planId)
        localStorage.setItem("plan", planName)
        setActiveSale(plansale)
    };

    return (
        <div>


            <div className="price1">
                <CustomTitle style={{ marginBottom: "24rem" }} title="Secure your special offer!" />
                {plans.map((plan) => (
                    <div
                        key={plan.id}
                        className={`price-plans__plan ${activePlan === plan.id ? 'price-plans__plan--active' : ''}`}
                        onClick={() => handlePlanClick(plan.id, plan.name, plan.sale)}
                    >
                        {plan.activeTrial &&
                            <div className="price-plans__plan-active-trial">{plan.activeTrial}</div>}
                        <div className="price-plans__top">

                            <div className="price-plans__top-info">
                                <h2 className="price-plans__plan-title">{plan.name}</h2>
                                {activePlan === plan.id && (
                                    <p className="price-plans__plan-price">



                                        {plan.price}
                                        <span>{plan.oldPrice}</span>
                                    </p>
                                )}

                            </div>
                            <div className="price-plans__top-trial">

                                <div className="price-plans__top-trial-last">

                                    <small>{plan.priceDay}</small>
                                    {activePlan === plan.id && (
                                        <i>{plan.sale}</i>
                                    )}

                                </div>

                            </div>
                        </div>



                        {activePlan === plan.id && (
                            <ul className="price-plans__features">
                                {plan.features.map((feature, index) => (
                                    <li key={index} className={!feature.status ? 'deactive' : ''}>
                                        {feature.status ? (
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.69531 7.00871L5.68677 9.70102L11.6697 4.31641" stroke="#2555FF" stroke-width="2" stroke-linecap="round" />
                                            </svg>

                                        ) : (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.17188 3.17188L8.82873 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M8.82812 3.17188L3.17127 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>

                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: feature.text }}></div>

                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                ))}
                <img src={process.env.PUBLIC_URL + '/img/payments.svg'} alt="" />
                <div className="plan-bottom plan-bottom--price1">

                    <div style={{ position: 'relative', marginBottom: '10rem', width: '100%' }}>
                        <PriceInfo sessionId={sessionId} eventName={'web_click_pricepricing'} setShowTermBundle={setShowTermBundle} text={`After ${plans[activePlan].after}: $79.95/month`} />
                    </div>
                    <MyButton
                        // ref={nextPageButtonRef}
                        buttonStatus={''}
                        buttonTime={150}
                        buttonText={`Get Growth with ${activeSale}`}
                        onClick={() => {
                            mixpanel.track('web_price', {
                                session_id: sessionId,
                                web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                                user_id: localStorage.getItem('userId'),
                                // web_pay_method: 'CreditCard',
                                web_utm_campaign: localStorage.getItem("utmCampaign"),
                                web_utm_source: localStorage.getItem("utmSource"),
                                web_utm_medium: localStorage.getItem("utmMedium"),
                                web_utm_content: localStorage.getItem("utmContent"),
                                web_utm_term: localStorage.getItem("utmTerm"),
                                isDebug: false,
                                funnel: 'MAIN_v1',
                            });

                            history.push('/trial');
                        }}

                    >
                    </MyButton>
                    <div className="plan-bottom-cancel">
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5022 16C9.54562 14.7416 12.9001 11.8019 12.9001 8.37157C12.9001 3.79985 12.9001 2.16577 12.9001 2.16577L6.5022 0L0.100098 2.16782C0.100098 2.16782 0.100098 3.8019 0.100098 8.37362C0.100098 11.8019 3.46089 14.7416 6.5022 16Z" fill="black" />
                            <path d="M3.7002 7.60012L5.7002 9.60012L9.3002 6.00012" stroke="white" stroke-width="1.2" stroke-linecap="round" />
                        </svg>
                        No commitments. Cancel anytime.

                    </div>
                </div>
            </div>


        </div>
    );
}

export default Price1;
