import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom';

const CheckEmail = ({}) => {
    const history = useHistory();
    const allowedPaths = ['/signup2', '/term', '/privacy', '/home1', '/home2', '/home3', '/home4', '/home5', '/home6', '/home7', '/start', '/test-solid'];
    useEffect(() => {

        if (!localStorage.getItem('accessToken') && !allowedPaths.includes(window.location.pathname)) {
            localStorage.setItem('checkEmailLInk', window.location.pathname);
            history.push('/signup2');
        }
    }, [window.location]);

    return (
        <></>
    )
}

export default CheckEmail