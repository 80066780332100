import React, { useState, useEffect } from 'react';
import LogoDark from '../components/Logo/LogoDark';
import CustomTitle from '../components/UI/title/CustomTitle';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import mixpanel from 'mixpanel-browser';

const HaveWebsite = ({ setButtonShow, setButtonTime, setSelectedOption, setButtonText, setWebsiteStatus, setEventProperties, sessionId, setEventName }) => {
    const [selectedRadio, setSelectedRadio] = useState(null);
    const handleRadioClick = (value, nextFunction) => {
        setSelectedRadio(value);
        if (value === 'option1') {

            localStorage.setItem('haveWebsite', "Yes");
            setWebsiteStatus(true)
        } else if (value === 'option2') {

            localStorage.setItem('haveWebsite', "No");
            setWebsiteStatus(false)

        }
        mixpanel.track('web_website', {
            session_id: sessionId,
            web_website_answer: value === 'option1' ? 'yes' : 'no',
            web_entrance: 'signup',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });
        setTimeout(() => {
            nextFunction();
        }, 350);
    };

    useEffect(() => {
        setButtonShow(false)
        setButtonTime(0);
        setSelectedOption('1');
        setButtonText('Continue');

        setEventName('web_website')
    }, []);

    return (
        <div>
            {/* <LogoDark style={{ marginBottom: '32rem', }} /> */}
            <CustomTitle style={{ marginBottom: "12rem" }} title="Do you have a website for your brand?" />
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'option1'} onClick={() => handleRadioClick('option1', next)} name='do' style={{ height: '42rem' }}>
                            Yes, I do
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'option2'} onClick={() => handleRadioClick('option2', next)} name='do' style={{ height: '42rem' }}>
                            No, not yet
                        </CustomRadio>
                    </>
                )}
            />
        </div>
    );
}

export default HaveWebsite;
