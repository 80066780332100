
import { useDispatch, useSelector } from 'react-redux';
import { setOverflow } from '../store/overflowBlockSlice';

const useOverflow = () => {
  const dispatch = useDispatch();
  const overflow = useSelector((state) => state.overflow.overflow);

  const changeOverflow = (value) => {
    dispatch(setOverflow(value));
  };

  return { overflow, changeOverflow };
};

export default useOverflow;
