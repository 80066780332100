import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './price.scss'
import { Helmet } from 'react-helmet';
import PriceInfo from '../../components/PriceInfo/PriceInfo';
import MyButton from '../../components/UI/button/MyButton';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import mixpanel from 'mixpanel-browser';
const Price2 = ({ setShowTermBundle, setButtonText, setButtonShow, setPriceBottomShow, setWhiteWrapper, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();
    useEffect(() => {
        setButtonShow(false);
        if (setWhiteWrapper) {
            setWhiteWrapper(true)
        }
        
        // setEventName('web_price')
        // setEventProperties({
        //     web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
        //     session_id: sessionId,
        //     user_id: localStorage.getItem('userId')
        // })
        setPriceBottomShow(false);
        // localStorage.setItem("price", 'plus')
        // localStorage.setItem("plan", '4-Week Plan')
        localStorage.setItem("price", 1)
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const LaunchIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#F2F4F6"/>
    <path d="M15.9699 19.8887C16.1582 20.9567 15.4451 21.9752 14.377 22.1635C13.309 22.3519 12.2905 21.6387 12.1022 20.5707C11.9822 19.8906 11.8823 19.3239 11.8024 18.8705C10.0988 18.5873 8.80078 17.1062 8.80078 15.3219C8.80078 13.3336 10.4126 11.7218 12.4009 11.7218H17.5833L20.1829 10.0668C21.0978 9.4843 22.3116 9.75376 22.894 10.6686C23.0946 10.9838 23.2012 11.3496 23.2012 11.7232V18.9206C23.2012 20.0052 22.3221 20.8843 21.2375 20.8843C20.8639 20.8843 20.4981 20.7778 20.1829 20.5771L17.5833 18.922L15.7994 18.9217C15.8479 19.1967 15.9047 19.519 15.9699 19.8887ZM13.3914 20.3433C13.4542 20.6993 13.7937 20.9371 14.1497 20.8743C14.5057 20.8115 14.7434 20.472 14.6807 20.116C14.5989 19.6522 14.5286 19.2539 14.47 18.9211L13.1407 18.9214C13.2093 19.3105 13.2929 19.7845 13.3914 20.3433ZM20.886 11.1711L17.9647 13.031V17.6129L20.886 19.4728C20.991 19.5397 21.113 19.5752 21.2375 19.5752C21.599 19.5752 21.8921 19.2822 21.8921 18.9206V11.7232C21.8921 11.5987 21.8566 11.4767 21.7897 11.3717C21.5955 11.0667 21.1909 10.9769 20.886 11.1711ZM16.6556 13.0308L12.4009 13.031C11.1356 13.031 10.1099 14.0567 10.1099 15.3219C10.1099 16.5872 11.1356 17.6129 12.4009 17.6129L16.6556 17.6128V13.0308Z" fill="black"/>
    </svg>
    const BuildIcon = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="16" fill="#F2F4F6"/>
    <path d="M13.1679 21.3334C11.6951 21.3334 10.5012 20.1395 10.5012 18.6667L10.5012 13.3334C10.5012 11.8607 11.6951 10.6667 13.1679 10.6667L19.8346 10.6667C21.3073 10.6667 22.5012 11.8607 22.5012 13.3334V18.6667C22.5012 20.1395 21.3073 21.3334 19.8346 21.3334H13.1679Z" stroke="black" stroke-width="1.04" stroke-linecap="round"/>
    <path d="M10.5012 18.802L13.5487 15.945C14.3519 15.1919 15.6128 15.2298 16.3695 16.0297L20.8346 20.7499" stroke="black" stroke-width="1.04" stroke-linecap="round"/>
    <path d="M18.5005 18.0832L19.965 16.8626C20.4948 16.421 21.2739 16.4564 21.7615 16.9441L22.5005 17.6831" stroke="black" stroke-width="1.04" stroke-linecap="round"/>
    <path d="M20.0429 14.0833C20.0429 14.8197 19.4459 15.4167 18.7096 15.4167C17.9732 15.4167 17.3762 14.8197 17.3762 14.0833C17.3762 13.347 17.9732 12.75 18.7096 12.75C19.4459 12.75 20.0429 13.347 20.0429 14.0833Z" stroke="black" stroke-width="1.04" stroke-linecap="round"/>
    </svg>
    const AiIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#F2F4F6"/>
    <g clip-path="url(#clip0_6009_7268)">
    <path d="M13.9168 13.6452C16.0067 12.9436 17.6511 11.2992 18.3527 9.20926C19.0544 11.2992 20.6988 12.9436 22.7887 13.6452C20.6988 14.3469 19.0544 15.9913 18.3527 18.0812C17.6511 15.9913 16.0067 14.3469 13.9168 13.6452Z" stroke="black" stroke-width="1.04"/>
    <path d="M9.29753 20.0125C10.5114 19.4942 11.4809 18.5247 11.9992 17.3108C12.5175 18.5247 13.487 19.4942 14.7009 20.0125C13.487 20.5308 12.5175 21.5003 11.9992 22.7142C11.4809 21.5003 10.5114 20.5308 9.29753 20.0125Z" stroke="black" stroke-width="1.04"/>
    </g>
    <defs>
    <clipPath id="clip0_6009_7268">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
    </svg>
    const ProductsIcon = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="16" fill="#F2F4F6"/>
    <path d="M9.48927 15.651C9.61503 14.1433 10.8754 12.9837 12.3883 12.9837H20.611C22.1239 12.9837 23.3843 14.1433 23.51 15.651L23.8732 20.0042C24.0147 21.7003 22.6762 23.1551 20.9741 23.1551H12.0251C10.3231 23.1551 8.98464 21.7003 9.12613 20.0042L9.48927 15.651Z" stroke="black" stroke-width="1.06667"/>
    <path d="M13.1055 12.604V12.239C13.1055 10.3645 14.625 8.84503 16.4994 8.84503V8.84503C18.3738 8.84503 19.8933 10.3645 19.8933 12.239V12.604" stroke="black" stroke-width="1.06667" stroke-linecap="round"/>
    <circle cx="13.954" cy="16.9657" r="0.848485" fill="black"/>
    <circle cx="19.0457" cy="16.9657" r="0.848485" fill="black"/>
    </svg>
    const CardIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#F2F4F6"/>
    <g clip-path="url(#clip0_6009_7287)">
    <rect x="9.45459" y="10.545" width="13.0909" height="10.9091" rx="1.45455" stroke="black"/>
    <rect x="9.45459" y="12.7269" width="13.0909" height="1.67273" rx="0.493506" fill="black"/>
    <rect x="10.9092" y="18.545" width="5.81818" height="1.16364" rx="0.581818" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_6009_7287">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
    </svg>
    const SupportIcon = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="16" fill="#F2F4F6"/>
    <path d="M10.5 20V16C10.5 14.4087 11.1321 12.8826 12.2574 11.7574C13.3826 10.6321 14.9087 10 16.5 10C18.0913 10 19.6174 10.6321 20.7426 11.7574C21.8679 12.8826 22.5 14.4087 22.5 16V20M22.5 20.6667C22.5 21.0203 22.3595 21.3594 22.1095 21.6095C21.8594 21.8595 21.5203 22 21.1667 22H20.5C20.1464 22 19.8072 21.8595 19.5572 21.6095C19.3071 21.3594 19.1667 21.0203 19.1667 20.6667V18.6667C19.1667 18.313 19.3071 17.9739 19.5572 17.7239C19.8072 17.4738 20.1464 17.3333 20.5 17.3333H22.5V20.6667ZM10.5 20.6667C10.5 21.0203 10.6405 21.3594 10.8905 21.6095C11.1406 21.8595 11.4797 22 11.8333 22H12.5C12.8536 22 13.1928 21.8595 13.4428 21.6095C13.6929 21.3594 13.8333 21.0203 13.8333 20.6667V18.6667C13.8333 18.313 13.6929 17.9739 13.4428 17.7239C13.1928 17.4738 12.8536 17.3333 12.5 17.3333H10.5V20.6667Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
    
    
    const [activePlan, setActivePlan] = useState(1);
    const [activeSale, setActiveSale] = useState('64% OFF');
    const plans = [
        {
            id: 0,
            name: '2 weeks ',
            after: 'two weeks',
            price: '$19.95',
            oldPrice: '$42.00',
            priceDay: '$1.42/day',
            sale: '54% OFF',
            features: [
                {
                    text: 'Launch up to 50 campaigns',
                    status: true,
                    icon: LaunchIcon,
                },
                {
                    text: 'Build up to 100 creatives',
                    status: true,
                    icon: BuildIcon
                },
                {
                    text: 'Create up to 10 AI avatar videos',
                    status: true,
                    icon: AiIcon,
                },
                {
                    text: 'Add unlimited products',
                    status: true,
                    icon: ProductsIcon,
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true,
                    icon: CardIcon,
                },
                {
                    text: 'Get 24/7 support anytime',
                    status: true,
                    icon: SupportIcon,
                },
            ]
        },
        {
            id: 1,
            name: '1 month',
            after: 'the first month',
            price: '$29.95',
            oldPrice: '$83.00',
            // activeTrial: 'Most Popular',
            priceDay: '$1.00/day',
            sale: '64% OFF',
            features: [
                {
                    text: 'Launch up to 100 campaigns',
                    status: true,
                    icon: LaunchIcon,
                },
                {
                    text: 'Build up to 250 creatives',
                    status: true,
                    icon: BuildIcon
                },
                {
                    text: 'Create up to 20 AI avatar videos',
                    status: true,
                    icon: AiIcon,
                },
                {
                    text: 'Add unlimited products',
                    status: true,
                    icon: ProductsIcon,
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true,
                    icon: CardIcon,
                },
                {
                    text: 'Get 24/7 support anytime',
                    status: true,
                    icon: SupportIcon,
                },
            ]
        },
        {
            id: 2,
            name: '2 months',
            after: 'two months',
            price: '$49.95',
            oldPrice: '$167.00',
            priceDay: '$0.86/day',
            sale: '70% OFF',
            features: [
                {
                    text: 'Launch up to 150 campaigns',
                    status: true,
                    icon: LaunchIcon,
                },
                {
                    text: 'Build up to 500 creatives',
                    status: true,
                    icon: BuildIcon
                },
                {
                    text: 'Create up to 30 AI avatar videos',
                    status: true,
                    icon: AiIcon,
                },
                {
                    text: 'Add unlimited products',
                    status: true,
                    icon: ProductsIcon,
                },
                {
                    text: 'Integrate Paypal & Stripe',
                    status: true,
                    icon: CardIcon,
                },
                {
                    text: 'Get 24/7 support anytime',
                    status: true,
                    icon: SupportIcon,
                },
            ]
        },
    ];


    const handlePlanClick = (planId, planName, plansale) => {
        setActivePlan(planId);
        localStorage.setItem("price", planId)
        localStorage.setItem("plan", planName)
        setActiveSale(plansale)
    };

    return (
        <div>


            <div className="price1">
                <CustomTitle style={{ marginBottom: "24rem" }} title="Secure your special offer!" />
                {plans.map((plan) => (
                    <div
                        key={plan.id}
                        className={`price-plans__plan price-plans__plan--second ${activePlan === plan.id ? 'price-plans__plan--active' : ''}`}
                        onClick={() => handlePlanClick(plan.id, plan.name, plan.sale)}
                    >
                        {plan.activeTrial &&
                            <div className="price-plans__plan-active-trial">{plan.activeTrial}</div>}
                        <div className="price-plans__top">

                            <div className="price-plans__top-info">
                                <h2 className="price-plans__plan-title">{plan.name}</h2>
                                {activePlan === plan.id && (
                                    <p className="price-plans__plan-price">



                                        {plan.price}
                                        <span>{plan.oldPrice}</span>
                                    </p>
                                )}

                            </div>
                            <div className="price-plans__top-trial">

                                <div className="price-plans__top-trial-last">

                                    <small>{plan.priceDay}</small>
                                    {activePlan === plan.id && (
                                        <i>{plan.sale}</i>
                                    )}

                                </div>

                            </div>
                        </div>



                        {activePlan === plan.id && (
                            <ul className="price-plans__features price-plans__features--second">
                                {plan.features.map((feature, index) => (
                                    <li key={index} className={!feature.status ? 'deactive' : ''}>
                                        {feature.icon}
                                        {feature.text}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                ))}
                <img src={process.env.PUBLIC_URL + '/img/payments.svg'} alt="" />
                <div className="plan-bottom plan-bottom--price1">

<div style={{ position: 'relative', marginBottom: '10rem', width: '100%' }}>
    <PriceInfo sessionId={sessionId} eventName={'web_click_pricepricing'} setShowTermBundle={setShowTermBundle} text={`After ${plans[activePlan].after}: $79.95/month`} />
</div>
<MyButton
    // ref={nextPageButtonRef}
    buttonStatus={''}
    buttonTime={150}
    buttonText={`Get Growth with ${activeSale}`}
    onClick={() => {
        mixpanel.track('web_price', {
            session_id: sessionId,
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            user_id: localStorage.getItem('userId'),
            // web_pay_method: 'CreditCard',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });
        history.push('/trial');
    }}

>
</MyButton>
<div className="plan-bottom-cancel">
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5022 16C9.54562 14.7416 12.9001 11.8019 12.9001 8.37157C12.9001 3.79985 12.9001 2.16577 12.9001 2.16577L6.5022 0L0.100098 2.16782C0.100098 2.16782 0.100098 3.8019 0.100098 8.37362C0.100098 11.8019 3.46089 14.7416 6.5022 16Z" fill="black" />
        <path d="M3.7002 7.60012L5.7002 9.60012L9.3002 6.00012" stroke="white" stroke-width="1.2" stroke-linecap="round" />
    </svg>
    No commitments. Cancel anytime.

</div>
</div>
            </div>
        

        </div>
    );
}

export default Price2;
