import React, { useState, useEffect } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './price10.scss'
import { Helmet } from 'react-helmet';
import PriceInfo from '../../components/PriceInfo/PriceInfo';

const Price10 = ({ setShowTermBundle, setButtonText, setButtonShow, setPriceBottomShow, setWhiteWrapper, setEventProperties, sessionId, setEventName, }) => {

    useEffect(() => {
        setButtonText("Get my plan");
        setButtonShow(true);
        if (setWhiteWrapper) {
            setWhiteWrapper(false)
        }
        setEventName('web_price')
        setEventProperties({
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            session_id: sessionId,
            user_id: localStorage.getItem('userId')
        })
        setPriceBottomShow(true);
        localStorage.setItem("price", '1')
        localStorage.setItem("plan", '4-Week Plan')
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const [activePlan, setActivePlan] = useState('1');

    const plans = [
        {
            id: '0',
            name: '2-Week Trial',
            price: '$19.95',
            oldPrice: '$42.00',
            activeTrial: '54% OFF',
            nameNumber: '2',
            nameText: 'weeks'

        },
        {
            id: '1',
            name: '4-Week Plan',
            price: '$29.95',
            oldPrice: '$83.00',
            activeTrial: '64% OFF',
            nameNumber: '1',
            nameText: ' month'
        },
        {
            id: '2',
            name: '8-Week Plan',
            price: '$49.95',
            oldPrice: '$167.00',
            activeTrial: '70% OFF', nameNumber: '2',
            nameText: ' months'
        },
    ];

    const handlePlanClick = (planId, planName) => {
        setActivePlan(planId);

        localStorage.setItem("price", planId)
        localStorage.setItem("plan", planName)

    };

    return (
        <div className="price-container">
            <Helmet>
                <meta name="theme-color" content="#000"></meta>
            </Helmet>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Choose your plan with<br>up to 70% discount" />
            <p style={{ marginBottom: "24rem" }} className="text">One-time offer, don't miss out!</p>
            <div className="price price10">
                <div className="price10-wrapper">
                    {plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`price-plans__plan ${activePlan === plan.id ? 'price-plans__plan--active' : ''}`}
                            onClick={() => handlePlanClick(plan.id, plan.name)}
                        >

                            <div className="price-plans__plan-active-trial">{plan.activeTrial}</div>
                            <div className="price-plans-body">
                                <div className="price-plans-body-wrap">
                                    <div className="price-plans__name">

                                        <strong>
                                            {plan.nameNumber}
                                        </strong>
                                        <span>{plan.nameText}</span>
                                    </div>
                                    <div className="price-plans__price">
                                        <strong>
                                            {plan.price}
                                        </strong>
                                        <span>{plan.oldPrice}</span>
                                    </div>
                                </div>

                            </div>



                            {/* {activePlan === plan.id && (
                            <ul className="price-plans__features">
                                {plan.features.map((feature, index) => (
                                    <li key={index} className={!feature.status ? 'deactive' : ''}>
                                        {feature.status ? (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.30859 6.00691L4.8727 8.3146L10.0009 3.69922" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                                            </svg>
                                        ) : (
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.17188 3.17188L8.82873 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M8.82812 3.17188L3.17127 8.82873" stroke="#8F969C" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>

                                        )}
                                        {feature.text}
                                    </li>
                                ))}
                            </ul>
                        )} */}

                        </div>
                    ))}
                </div>

                <div style={{ position: 'relative' }}>
                    <PriceInfo sessionId={sessionId} eventName={'web_click_pricepricing'} setShowTermBundle={setShowTermBundle} text="First subscription discounted automatically" />
                </div>
            </div>
            <div className="plan-bottom">

            </div>

        </div>
    );
}

export default Price10;
