
import { configureStore } from '@reduxjs/toolkit';
import overflowReducer from './overflowBlockSlice';
import blockClassNameReducer from "./blockClassNameSlice"; 

const store = configureStore({
  reducer: {
    overflow: overflowReducer,
    blockClassName: blockClassNameReducer,
  },
});

export default store;
