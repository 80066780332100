import React, { useEffect, useState } from 'react';
import LogoDark from '../components/Logo/LogoDark';
import CustomTitle from '../components/UI/title/CustomTitle';
import CustomRadio from '../components/UI/radio/CustomRadio';
import { WithWizard } from 'react-albus-react18';
import mixpanel from 'mixpanel-browser';

const WebsiteType = ({ setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setButtonTime, setAiShow, setButtonStatus, setBlockPaddingBottom, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonText('Get started')
        setButtonShow(false);
        setButtonTime(0);
        setSelectedOption(null)
        setAiShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setEventName('web_platform')
    }, []);

    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "E-commerce store",
            img: 'ecommerce.svg',
        },
        {
            id: 'option2',
            checked: false,
            text: "Landing page",
            img: 'landing.svg',
        },
        {
            id: 'option3',
            checked: false,
            text: "Portfolio page",
            img: 'portfolio.svg',
        },
        {
            id: 'option4',
            checked: false,
            text: "Personal site",
            img: 'personal.svg',
        },
        {
            id: 'option5',
            checked: false,
            text: "I’d like to create my site later",
            img: 'later.svg',
        }
    ];



    const handleCheckboxChange = (checkbox, nextFunction) => {


        setSelectedOption(checkbox.id);
        // if(checkbox.id == 'option6') {
        //     setShowTyping(true);
        // } else {
        //     setShowTyping(false);
        // }
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.siteType = checkbox.text
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                siteType: checkbox.text
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        setEventProperties({
           
        })

        mixpanel.track('web_platform', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_site_type: checkbox.text,
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });

        setTimeout(() => {
            nextFunction();
        }, 350);

        setError(false);
    };

    const [showTyping, setShowTyping] = useState(false);





    return (
        <div >
            <CustomTitle style={{ marginBottom: "12rem" }} title="That’s great! AI will generate a site for you" />
            <p style={{ marginBottom: "24rem" }} className="text">
                Select the type of site you want to create. We'll get it going in less than 1 minute!
            </p>
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        {initialCheckboxState.map((checkbox) => (
                            <CustomRadio
                                key={checkbox.id}
                                id={checkbox.id}
                                checked={selectedOption === checkbox.id}
                                onChange={() => handleCheckboxChange(checkbox, next)}
                                className={error ? 'error' : undefined}
                                style={{ marginBottom: '20rem' }}
                            >
                                <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} style={{ minWidth: '20rem', width: "20rem", height: "20rem", borderRadius: '0' }} alt="" />
                                {checkbox.text}
                            </CustomRadio>
                        ))}
                    </>
                )}
            />

            {/* {showTyping && (
                <div className="input">
                    <input
                        type="text" placeholder='Enter name of your platform' />
                </div>
            )} */}
        </div >
    );
}

export default WebsiteType;
