import { createSlice } from "@reduxjs/toolkit";

const initialState = ''; // Изначальное состояние

const blockClassNameSlice = createSlice({
  name: 'blockClassName',
  initialState,
  reducers: {
    setBlockClassName: (state, action) => action.payload, // Обновляем состояние
  },
});

export const { setBlockClassName } = blockClassNameSlice.actions;
export default blockClassNameSlice.reducer;
