import { useDispatch, useSelector } from "react-redux";
import { setBlockClassName } from "../store/blockClassNameSlice";


const useBlockClassName = () => {
  const dispatch = useDispatch();
  
  const blockClassName = useSelector((state) => state.blockClassName);

  const updateBlockClassName = (newClassName) => {
    dispatch(setBlockClassName(newClassName));
  };

  return {
    blockClassName,
    updateBlockClassName,
  };
};

export default useBlockClassName;
