import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  overflow: 'hidden',
};

const overflowSlice = createSlice({
  name: 'overflow',
  initialState,
  reducers: {
    setOverflow: (state, action) => {
      state.overflow = action.payload;
    },
  },
});

export const { setOverflow } = overflowSlice.actions;
export default overflowSlice.reducer;
